<template>
  <component :is="iconSvg" :key="icon" :class="$style.icon" :style="css"></component>
</template>

<script setup lang="ts">
import type { FunctionalComponent, SVGAttributes } from "vue";
import { computed } from "vue";

import { assertIsDefined } from "@/helpers/types";

const iconModules = import.meta.glob<FunctionalComponent<SVGAttributes>>(
  "../../node_modules/@acrevis/icons/svg/**/*.svg",
  {
    eager: true,
    as: "component",
  },
);

const iconMap = Object.entries(iconModules).reduce<Record<string, FunctionalComponent<SVGAttributes>>>((prev, icon) => {
  const [iconPath, iconModule] = icon;
  const iconName = iconPath.split("/");

  const iconNameWithoutExtension = iconName[iconName.length - 1]?.split(".svg")[0];
  assertIsDefined(iconNameWithoutExtension, "iconNameWithoutExtension");

  return {
    ...prev,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    [`${iconName[iconName.length - 2]}/${iconNameWithoutExtension}`]: iconModule,
  };
}, {});

const props = withDefaults(
  defineProps<{
    size?: string;
    icon: string;
  }>(),
  {
    size: "24px",
  },
);

const css = computed(() => ({
  "--size": props.size,
}));

const iconSvg = computed((): FunctionalComponent<SVGAttributes> | null => {
  if (props.icon === null) {
    return null;
  }
  try {
    return iconMap[props.icon] ?? null;
  } catch (e) {
    console.warn("Icon not found: " + props.icon);
    return iconMap["navigation/error"] ?? null;
  }
});
</script>

<style lang="scss" module>
.icon {
  width: var(--size) !important;
  height: var(--size) !important;
  display: inline-block;
}
a:hover > .icon path {
  color: $red-one;
}
</style>
