import { createOidcAuth, SignInType } from "vue-oidc-client/vue3";

import { oidcConfig } from "./config";

const appUrl = `${window.location.origin}/`;

export interface User {
  readonly finnova_username: string | null;
  readonly azp: string;
  readonly email: string;
  readonly email_verfied: boolean;
  readonly family_name: string;
  readonly given_name: string;
  readonly hd: string;
  readonly jti: string;
  readonly locale: string;
  readonly name: string;
  readonly picture: string;
  readonly sub: string;
  readonly wwwliipBirthdate: string;
  readonly wwwliipCity: string;
  readonly wwwliipDomicile: string;
  readonly wwwliipPhone: string;
  readonly wwwliipSalutation: string;
  readonly wwwliipStreet: string;
  readonly wwwliipZipcode: string;
}

const oidcAuth = createOidcAuth("main", SignInType.Window, appUrl, {
  authority: oidcConfig.authority,
  client_id: oidcConfig.clientId,
  response_type: "code",
  scope: "openid email profile acrevis acrevis-consultant",
  loadUserInfo: true,
  redirect_uri: `${appUrl}auth/signinwin`,
  silent_redirect_uri: `${appUrl}auth/signinsilent`,
  post_logout_redirect_uri: appUrl,
  automaticSilentRenew: true,
});

oidcAuth.startSilentRenew();

export default oidcAuth;
