<template>
  <header :class="$style.header" class="bg-white d-flex">
    <div class="me-auto align-self-center">
      <ACVLogo style class="d-inline-block" :class="$style.logo" />
    </div>

    <a
      class="text-decoration-none h-100 d-flex align-items-stretch"
      href="https://www.acrevis.ch/privat/konto-und-karte/konto-online-eroeffnen"
      target="_blank"
    >
      <ACVMetaNavigationItem data-testid="nav-faq" :label="$gettext('FAQ')">
        <template #icon>
          <SvgIcon size="auto" icon="navigation/faq" />
        </template>
      </ACVMetaNavigationItem>
    </a>
    <a
      class="text-decoration-none h-100 d-flex align-items-stretch"
      href="https://www.acrevis.ch/kontakt"
      target="_blank"
    >
      <ACVMetaNavigationItem data-testid="nav-contact" :label="$gettext('Kontakt')">
        <template #icon>
          <SvgIcon size="auto" icon="symbols/chat" />
        </template>
      </ACVMetaNavigationItem>
    </a>

    <ACVDropdown v-if="isAuthenticated">
      <template #trigger="{ expanded }">
        <ACVMetaNavigationItem :label="userName" :active="expanded">
          <template #icon>
            <SvgIcon size="100%" icon="symbols/consultant-login" />
          </template>
        </ACVMetaNavigationItem>
      </template>
      <div :class="$style.dropdownContent">
        <!-- Logout -->
        <ACVLink variant="NAV" :class="$style.dropdownItem" @click="logout">
          <template #icon-left>
            <SvgIcon size="25px" icon="symbols/power" />
          </template>
          {{ $gettext("Logout") }}
        </ACVLink>
      </div>
    </ACVDropdown>

    <router-link v-if="isDev" :to="{ name: '_dev' }" class="text-decoration-none h-100 d-flex align-items-stretch">
      <ACVMetaNavigationItem data-testid="nav-dev" :label="$gettext('_DEV')">
        <template #icon>
          <SvgIcon icon="symbols/rocket" size="40px" />
        </template>
      </ACVMetaNavigationItem>
    </router-link>
  </header>
</template>

<script setup lang="ts">
import { ACVDropdown, ACVLink, ACVLogo, ACVMetaNavigationItem } from "@acrevis/components";
import { computed } from "vue";
import { useGettext } from "vue3-gettext";

import SvgIcon from "@/components/SvgIcon.vue";
import { baseConfig } from "@/config";
import oidcAuth from "@/oidc";

const isDev = baseConfig.dev;

const { $gettext } = useGettext();

const userName = computed(() => {
  return oidcAuth.userProfile.given_name || $gettext("Vorname");
});

const logout = async () => {
  await oidcAuth.signOut();
};

const isAuthenticated = computed(() => oidcAuth.isAuthenticated);
</script>

<style lang="scss" module>
.header {
  padding-left: map-get($spacers, "c-16");
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  box-shadow: 0 4px 4px rgb(0 0 0 / 6%);
  z-index: 100;
  height: var(--header-height);

  @include media-breakpoint-up(md) {
    padding-left: map-get($spacers, "c-32");
    padding-right: map-get($spacers, "c-16");
  }

  .logo {
    width: 106px;
    @include media-breakpoint-up(xl) {
      width: 128px;
    }
  }

  .dropdownContent {
    border: 1px solid $grey-four;
    background-color: $white;
    padding: 24px 70px 24px 50px;
    max-width: 400px;
    max-height: 500px;
    overflow: auto;
    z-index: 1000;

    .dropdownItem {
      margin: map-get($spacers, "c-16") 0;
    }
  }
}
</style>
