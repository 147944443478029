import { IntegrationApiMasterdataCore } from "@acrevis/integration-api-masterdata-core";

import { servicesConfig } from "@/config";

export const integrationApiMasterdataCoreConfig = new IntegrationApiMasterdataCore({
  BASE: servicesConfig.integration.masterdata.api,
});

export const advisorsIntegrationServiceApiClient = integrationApiMasterdataCoreConfig.advisors;
export const countriesIntegrationServiceApiClient = integrationApiMasterdataCoreConfig.countries;
export const zipCodeLocationIntegrationServiceApiClient = integrationApiMasterdataCoreConfig.zipCities;
