import type { RouteRecordRaw } from "vue-router";

import { SuitabilityRoutes } from "@/router/suitability.enum";
import { useProcessStore } from "@/store/process";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: SuitabilityRoutes.DOMICILE,
    component: () => import("@/pages/suitability/Domicile.vue"),
    meta: {
      isPublic: true,
    },
    beforeEnter() {
      useProcessStore().reset();
      return true;
    },
  },
  {
    path: "/terms",
    name: SuitabilityRoutes.TERMS,
    component: () => import("@/pages/suitability/Terms.vue"),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/existing-client",
    name: SuitabilityRoutes.EXISTING_CLIENT_QUESTION,
    component: () => import("@/pages/suitability/ExistingClient.vue"),
    meta: {
      isPublic: true,
    },
  },
];

export default routes;
