import type { Address, ProspectDouble } from "@acrevis/curo-api-client";
import { ProspectSingle } from "@acrevis/curo-api-client";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type { RouteLocationNamedRaw, RouteLocationPathRaw } from "vue-router";

import { ContactMethodType } from "@/api/services/curo";
import { assertIsDefined } from "@/helpers/types";
import { ProcessRoutes } from "@/router/process.enum";
import { useLogger } from "@/services";
import { useAddressDataStore } from "@/store/process/steps/addressData";
import { useConfirmationStore } from "@/store/process/steps/confirmation";
import { useIdentificationStore } from "@/store/process/steps/identification";
import { useImportantQuestionsStore } from "@/store/process/steps/importantQuestions";
import { usePersonalDataStore } from "@/store/process/steps/personalData";
import { useProductSelectionStore } from "@/store/process/steps/productSelection";

export enum ProcessStepType {
  CLIENT_TYPE = "CLIENT_TYPE",
  PERSONAL_DATA = "PERSONAL_DATA",
  PRODUCT_SELECTION = "PRODUCT_SELECTION",
  ADDITIONAL_PRODUCT_SELECTION = "ADDITIONAL_PRODUCT_SELECTION",
  EXTENDED_PERSONAL_DATA = "EXTENDED_PERSONAL_DATA",
  EDUCATION_QUESTION = "EDUCATION_QUESTION",
  EDUCATION_PROOF = "EDUCATION_PROOF",
  IMPORTANT_QUESTIONS = "IMPORTANT_QUESTIONS",
  ADDRESS_DATA = "ADDRESS_DATA",
  LEAD_CLIENT_QUESTION = "LEAD_CLIENT_QUESTION",
  CONFIRMATION = "CONFIRMATION",
  IDENTIFICATION_ENTRY = "IDENTIFICATION_ENTRY",
  IDENTIFICATION = "IDENTIFICATION",
}

export interface ProcessStep {
  type: ProcessStepType;
  route: RouteLocationPathRaw | RouteLocationNamedRaw;
}

const logger = useLogger();

const getQuestionAnswer = (personIndex: number, questionIndex: number): boolean | undefined => {
  const importantQuestionsStore = useImportantQuestionsStore();
  const questionDataSets = importantQuestionsStore.dataSets;

  if (!questionDataSets[personIndex]) return;

  const clientDataSet = questionDataSets[personIndex];

  if (!clientDataSet?.[questionIndex]) return;

  const clientQuestion = clientDataSet[questionIndex];

  if (!clientQuestion || clientQuestion?.answer === null) return undefined;

  return clientQuestion.answer;
};

export const useProcessStore = defineStore(
  "process",
  () => {
    const personalDataStore = usePersonalDataStore();
    const addressDataStore = useAddressDataStore();
    const importantQuestionsStore = useImportantQuestionsStore();
    const productSelectionStore = useProductSelectionStore();
    const confirmationStore = useConfirmationStore();
    const identificationStore = useIdentificationStore();

    const currentProcessStep = ref<ProcessStepType>(ProcessStepType.CLIENT_TYPE);
    const processes = ref<ProcessStep[]>([
      { type: ProcessStepType.CLIENT_TYPE, route: { name: ProcessRoutes.CLIENT_TYPE } },
      { type: ProcessStepType.PERSONAL_DATA, route: { name: ProcessRoutes.PERSONAL_DATA } },
      {
        type: ProcessStepType.EDUCATION_QUESTION,
        route: {
          name: ProcessRoutes.EDUCATION_QUESTION,
        },
      },
      { type: ProcessStepType.PRODUCT_SELECTION, route: { name: ProcessRoutes.PRODUCT_SELECTION } },
      {
        type: ProcessStepType.ADDITIONAL_PRODUCT_SELECTION,
        route: { name: ProcessRoutes.ADDITIONAL_PRODUCT_SELECTION },
      },
      { type: ProcessStepType.EXTENDED_PERSONAL_DATA, route: { name: ProcessRoutes.EXTENDED_PERSONAL_DATA } },
      {
        type: ProcessStepType.EDUCATION_PROOF,
        route: {
          name: ProcessRoutes.EDUCATION_PROOF,
        },
      },
      { type: ProcessStepType.ADDRESS_DATA, route: { name: ProcessRoutes.ADDRESS_DATA } },
      {
        type: ProcessStepType.IMPORTANT_QUESTIONS,
        route: {
          name: ProcessRoutes.IMPORTANT_QUESTIONS,
          params: {
            currentQuestionIndex: "0",
            currentUserIndex: "0",
          },
        },
      },
      { type: ProcessStepType.LEAD_CLIENT_QUESTION, route: { name: ProcessRoutes.LEAD_CLIENT_QUESTION } },
      { type: ProcessStepType.CONFIRMATION, route: { name: ProcessRoutes.CONFIRMATION } },
      { type: ProcessStepType.IDENTIFICATION_ENTRY, route: { name: ProcessRoutes.IDENTIFICATION_ENTRY } },
    ]);

    const currentStep = computed(() => {
      return processes.value.find((process) => process.type === currentProcessStep.value);
    });

    // Curo

    const curoAddresses = computed(() => {
      return addressDataStore.dataSets.map((addressDataSet) => {
        assertIsDefined(addressDataSet, "addressDataSet");
        const fallbackAddressDataSet = addressDataStore.dataSets[0];
        assertIsDefined(fallbackAddressDataSet, "fallbackAddressDataSet");
        const addressToUse = addressDataSet.hasSameAddress ? fallbackAddressDataSet : addressDataSet;

        if (!addressToUse.location) return null;

        assertIsDefined(addressToUse.street, "addressToUse.street");
        assertIsDefined(addressToUse.location.zip, "addressToUse.location.zip");
        assertIsDefined(addressToUse.location.cityLong, "addressToUse.location.cityLong");

        const address: Address = {
          street: addressToUse.street,
          streetNumber: addressToUse.streetNumber ?? undefined,
          zip: addressToUse.location.zip.toString(),
          city: addressToUse.location.cityLong,
          country: "CHE",
        };
        return address;
      });
    });

    const curoClients = computed(() => {
      return personalDataStore.dataSets.map((personalDataSet, index) => {
        const addressDataSet = addressDataStore.dataSets[index];
        const questionDataSets = importantQuestionsStore.dataSets;
        const address = curoAddresses.value[index];

        if (!personalDataSet || !addressDataSet || !address) throw new Error(`Data missing for client ${index}`);

        const dbaBenefitLocation = personalDataStore.isDoubleClient
          ? questionDataSets[2]
            ? questionDataSets[2][0]
            : null
          : questionDataSets[0]?.[3];

        assertIsDefined(personalDataSet.firstName, "personalDataSet.firstName");
        assertIsDefined(personalDataSet.lastName, "personalDataSet.lastName");
        assertIsDefined(personalDataSet.nationality, "personalDataSet.nationality");
        assertIsDefined(personalDataSet.phoneNumber, "personalDataSet.phoneNumber");
        assertIsDefined(personalDataSet.email, "personalDataSet.email");

        const prospect: ProspectSingle = {
          firstName: personalDataSet.firstName,
          lastName: personalDataSet.lastName,
          gender: personalDataSet.gender === "MALE" ? ProspectSingle.gender.MALE : ProspectSingle.gender.FEMALE,
          birthDate: personalDataSet.dateOfBirth ? personalDataSet.dateOfBirth.substr(0, 10) : "",
          nationalities: [
            personalDataSet.nationality,
            ...(!personalDataSet.hasNotSecondNationality && personalDataSet.nationality2
              ? [personalDataSet.nationality2]
              : []),
          ],
          inEducation: personalDataSet.inEducation ? personalDataSet.inEducation : false,
          address,
          contactMethods: [
            { value: personalDataSet.phoneNumber, type: ContactMethodType.MOBILE_PRIVATE },
            { value: personalDataSet.email, type: ContactMethodType.MAIL_PRIVATE },
          ],
          fatca: {
            usCitizen: getQuestionAnswer(index, 2),
            usBirth: getQuestionAnswer(index, 2),
            usResidentGreencard: getQuestionAnswer(index, 2),
            substancialPresence: getQuestionAnswer(index, 2),
            taxableOtherReason: getQuestionAnswer(index, 2),
            domicilCh: true,
            dbaBenefit:
              dbaBenefitLocation && dbaBenefitLocation.answer !== null ? dbaBenefitLocation.answer : undefined,
            aiaTaxResidencyCh: getQuestionAnswer(index, 1),
            wibeBeneficialOwner: getQuestionAnswer(index, 0),
          },
          type: "ProspectSingle",
        };
        return prospect;
      });
    });

    const curoClientSingle = computed(() => {
      console.log(curoClients.value);
      if (personalDataStore.isDoubleClient === true) return;

      return curoClients.value[0];
    });

    const curoClientDouble = computed((): ProspectDouble | undefined => {
      if (personalDataStore.isDoubleClient === false) return;
      if (!personalDataStore.doubleClientData.legalStatus) return;
      assertIsDefined(curoClients.value[0], "curoClients.value[0]");
      assertIsDefined(curoClients.value[1], "curoClients.value[1]");
      return {
        legalStatus: personalDataStore.doubleClientData.legalStatus,
        prospectSingles: [curoClients.value[0], curoClients.value[1]],
        type: "ProspectDouble",
      };
    });

    const curoClientDoubleSortedByLeadClient = computed((): ProspectDouble | undefined => {
      const doubleClient = curoClientDouble.value;
      if (!doubleClient) return;

      return {
        ...doubleClient,
        prospectSingles: [
          ...doubleClient.prospectSingles.sort((a) =>
            a === doubleClient.prospectSingles[personalDataStore.leadClientIndex || 0] ? -1 : 1,
          ),
        ],
      };
    });

    const continueToNextStep = () => {
      const currentIndex = processes.value.findIndex((process) => process.type === currentProcessStep.value);
      const nextStep = processes.value[currentIndex + 1];
      assertIsDefined(nextStep, "nextStep");

      logger.debug("Continuing to next Step...");
      logger.debug("Current index", currentIndex);
      logger.debug("Next Step", nextStep.type, nextStep);

      const futureStep = processes.value[currentIndex + 2];

      if (nextStep.type === ProcessStepType.LEAD_CLIENT_QUESTION && !personalDataStore.isDoubleClient) {
        logger.debug("Next step is lead client question, but is not double client, so skipping...");
        assertIsDefined(futureStep, "futureStep");
        currentProcessStep.value = futureStep.type;
      } else if (
        nextStep.type === ProcessStepType.EDUCATION_QUESTION &&
        !personalDataStore.isEligibleForEducationTerms
      ) {
        logger.debug("Next step is education question, but is not eligible, so skipping...");
        assertIsDefined(futureStep, "futureStep");
        currentProcessStep.value = futureStep.type;
      } else if (nextStep.type === ProcessStepType.EDUCATION_PROOF && !personalDataStore.isEligibleForEducationTerms) {
        logger.debug("Next step is education proof, but is not eligible, so skipping...");
        assertIsDefined(futureStep, "futureStep");
        currentProcessStep.value = futureStep.type;
      } else {
        currentProcessStep.value = nextStep.type;
      }
    };

    const goBackToLastStep = () => {
      const currentIndex = processes.value.findIndex((process) => process.type === currentProcessStep.value);
      const lastStep = processes.value[currentIndex - 1];
      assertIsDefined(lastStep, "lastStep");

      logger.debug("Going back to last Step...");
      logger.debug("Current index", currentIndex);
      logger.debug("Last Step", lastStep.type, lastStep);

      const previousStep = processes.value[currentIndex - 2];

      if (lastStep.type === ProcessStepType.LEAD_CLIENT_QUESTION && !personalDataStore.isDoubleClient) {
        logger.debug("Last step is lead client question, but is not double client, so skipping...");
        assertIsDefined(previousStep, "previousStep");
        currentProcessStep.value = previousStep.type;
      } else if (
        lastStep.type === ProcessStepType.EDUCATION_QUESTION &&
        !personalDataStore.isEligibleForEducationTerms
      ) {
        logger.debug("Last step is education question, but is not eligible, so skipping...");
        assertIsDefined(previousStep, "previousStep");
        currentProcessStep.value = previousStep.type;
      } else if (lastStep.type === ProcessStepType.EDUCATION_PROOF && !personalDataStore.isEligibleForEducationTerms) {
        logger.debug("Last step is education proof, but is not eligible, so skipping...");
        assertIsDefined(previousStep, "previousStep");
        currentProcessStep.value = previousStep.type;
      } else {
        currentProcessStep.value = lastStep.type;
      }
    };

    const setCurrentProcessStep = (step: ProcessStepType) => {
      currentProcessStep.value = step;
    };

    const reset = () => {
      personalDataStore.$reset();
      productSelectionStore.$reset();
      addressDataStore.$reset();
      importantQuestionsStore.$reset();
      confirmationStore.$reset();
      identificationStore.$reset();
      useProcessStore().$reset();
    };

    return {
      currentProcessStep,
      processes,
      currentStep,
      curoClientSingle,
      curoClientDouble,
      curoClientDoubleSortedByLeadClient,
      continueToNextStep,
      goBackToLastStep,
      setCurrentProcessStep,
      reset,
    };
  },
  {
    persist: true,
  },
);
