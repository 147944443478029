export enum AuthRoutes {
  OIDC_CALLBACK = "oidcCallback",
  OIDC_SILENT = "oidcSilent",
}

export default [
  {
    path: "/oidc-callback",
    name: AuthRoutes.OIDC_CALLBACK,
    component: () => import("@/pages/auth/OidcCallback.vue"),
    meta: {
      isOidcCallback: true,
      isPublic: true,
    },
  },
];
