import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import devRoutes from "@/router/_dev";
import { RootRoutes } from "@/router/index.enum";
import processRoutes from "@/router/process";
import suitabilityRoutes from "@/router/suitability";

import authRoutes from "./auth";

const routes: RouteRecordRaw[] = [
  ...suitabilityRoutes,
  {
    path: "/exit/:reason?",
    name: RootRoutes.EXIT,
    component: () => import("@/pages/Exit.vue"),
    props: true,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/completed",
    name: RootRoutes.COMPLETED,
    component: () => import("@/pages/Completed.vue"),
    props: (route) => ({
      success: String(route.query.success).toLowerCase() === "true",
      reason: route.query.reason || null,
    }),
    meta: {
      isPublic: true,
    },
  },
  {
    path: "/reset",
    name: RootRoutes.RESET,
    component: () => import("@/pages/Reset.vue"),
    meta: {
      isPublic: true,
    },
  },
  ...processRoutes,
  ...authRoutes,
  ...devRoutes,
  {
    path: "/:notFound",
    component: () => import("@/pages/NotFound.vue"),
    meta: {
      isPublic: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
