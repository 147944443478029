import { inject } from "vue";

import { selfOnboardingControllerApiClient } from "./services/curo";
import {
  advisorsIntegrationServiceApiClient,
  countriesIntegrationServiceApiClient,
  zipCodeLocationIntegrationServiceApiClient,
} from "./services/integration";

export const apiClients = {
  curo: {
    selfOnboardingControllerApiClient: selfOnboardingControllerApiClient,
  },
  integration: {
    advisors: advisorsIntegrationServiceApiClient,
    countries: countriesIntegrationServiceApiClient,
    zipCodeLocation: zipCodeLocationIntegrationServiceApiClient,
  },
};

export type ApiClientsConfig = typeof apiClients;
export const useApi = () => inject<ApiClientsConfig>("api");
