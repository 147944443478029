import type { RouteRecordRaw } from "vue-router";

import { baseConfig } from "@/config";

let routes: RouteRecordRaw[] = [];

if (baseConfig.dev) {
  routes = [
    {
      path: "/_dev",
      name: "_dev",
      component: () => import("@/pages/_Dev.vue"),
    },
  ];
}

export default routes;
