export enum ProcessRoutes {
  ROOT = "process",
  CLIENT_TYPE = "clientType",
  PERSONAL_DATA = "personalData",
  PRODUCT_SELECTION = "productSelection",
  ADDITIONAL_PRODUCT_SELECTION = "additionalProductSelection",
  EXTENDED_PERSONAL_DATA = "extendedPersonalData",
  EDUCATION_QUESTION = "educationQuestion",
  EDUCATION_PROOF = "educationProof",
  LEAD_CLIENT_QUESTION = "leadClientQuestion",
  IMPORTANT_QUESTIONS = "importantQuestions",
  ADDRESS_DATA = "addressData",
  CONFIRMATION = "confirmation",
  IDENTIFICATION_ENTRY = "identificationEntry",
  IDENTIFICATION = "identification",
}

export enum EducationSteps {
  QUESTION = "question",
  PROOF = "proof",
}
