import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { assertIsDefined } from "@/helpers/types";

export type QuestionType = "OWNERSHIP" | "TAX_RESIDENCY" | "US_TAX_STATUS" | "DOUBLE_TAXATION_AGREEMENT";

export interface QuestionsMap {
  type: QuestionType;
  answer: boolean | null;
}

const createDefaultQuestionsMaps = (): QuestionsMap[] => [
  {
    type: "OWNERSHIP",
    answer: null,
  },
  {
    type: "TAX_RESIDENCY",
    answer: null,
  },
  {
    type: "US_TAX_STATUS",
    answer: null,
  },
  {
    type: "DOUBLE_TAXATION_AGREEMENT",
    answer: null,
  },
];

export const useImportantQuestionsStore = defineStore(
  "importantQuestions",
  () => {
    const finished = ref(false);
    const dataSets = ref<QuestionsMap[][]>([createDefaultQuestionsMaps()]);

    const setFinished = () => {
      finished.value = true;
    };

    const completedDataCollection = computed(() => {
      if (!dataSets.value[0]) return [];

      const completionState = [true];

      const dataSetFirstUser = dataSets.value[0];
      completionState[0] = dataSetFirstUser.every((question) => question.answer !== null);

      if (dataSets.value[1]) {
        const dataSet2ndUser = dataSets.value[1];
        completionState[1] = dataSet2ndUser.every((question) => question.answer !== null);
      }
      // return [true, true]; // add this line to quickly navigate
      return completionState;
    });

    const updateAnswerForSetAtIndex = (payload: { setIndex: number; questionIndex: number; answer: boolean }) => {
      const dataSetsClone = [...dataSets.value];
      const item = dataSetsClone[payload.setIndex];
      assertIsDefined(item, "item");
      const questions = [...item];
      const questionItem = questions[payload.questionIndex];
      assertIsDefined(questionItem, "questionItem");
      questions[payload.questionIndex] = {
        ...questionItem,
        answer: payload.answer,
      };
      dataSetsClone[payload.setIndex] = questions;
      dataSets.value = dataSetsClone;
    };

    const setDoubleClient = () => {
      if (dataSets.value.length >= 2) return;
      dataSets.value = [
        [
          {
            type: "OWNERSHIP",
            answer: null,
          },
          {
            type: "TAX_RESIDENCY",
            answer: null,
          },
          {
            type: "US_TAX_STATUS",
            answer: null,
          },
        ],
        [
          {
            type: "OWNERSHIP",
            answer: null,
          },
          {
            type: "TAX_RESIDENCY",
            answer: null,
          },
          {
            type: "US_TAX_STATUS",
            answer: null,
          },
        ],
        [
          {
            type: "DOUBLE_TAXATION_AGREEMENT",
            answer: null,
          },
        ],
      ];
    };

    const setSingleClient = () => {
      if (dataSets.value.length < 2) return;
      dataSets.value = [createDefaultQuestionsMaps()];
    };

    return {
      finished,
      dataSets,
      setFinished,
      completedDataCollection,
      updateAnswerForSetAtIndex,
      setDoubleClient,
      setSingleClient,
    };
  },
  {
    persist: true,
  },
);
