import type { LogService } from "./log.service";

export interface ContextAwareError extends Error {
  getContext(): object;
}

export function isContextAwareError(error: ContextAwareError | Error): error is ContextAwareError {
  return (<ContextAwareError>error).getContext !== undefined;
}

export class ErrorService {
  logger: LogService;

  constructor(logger: LogService) {
    this.logger = logger;
  }

  public throwError = (error: Error | ContextAwareError): never => {
    this.logger.error(error.message);
    throw error;
  };
}
