import { useLogger } from "@/services";

import type { RuntimeConfig } from "../config";

const logger = useLogger();

export function getData(dataSelect: string): Partial<RuntimeConfig> {
  try {
    const inlineJsonElement = document.querySelector(`script[type="application/json"][data-selector="${dataSelect}"]`);
    if (!inlineJsonElement?.textContent) {
      return {};
    }
    if (inlineJsonElement.textContent.trim() === "// RUNTIME_CONFIGURATION") {
      return {};
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(inlineJsonElement.textContent);
  } catch (err) {
    logger.error(`Couldn't read JSON data from ${dataSelect}`, err);
    return {};
  }
}

export function getRuntimeConfig(): Partial<RuntimeConfig> {
  try {
    const runtimeConfig = getData("runtime-config");
    logger.debug("Runtime config was fetched from meta tag.", runtimeConfig);
    return runtimeConfig;
  } catch (error) {
    logger.debug("Runtime config could not be fetched from meta tag.", error);
    return {};
  }
}
