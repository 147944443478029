import { defineStore } from "pinia";
import { ref } from "vue";

export const useIdentificationStore = defineStore(
  "identification",
  () => {
    const finished = ref(false);

    const setFinished = () => {
      finished.value = true;
    };

    return {
      finished,
      setFinished,
    };
  },
  {
    persist: true,
  },
);
