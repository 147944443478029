export function calculateDateOffsetInYears(firstDate: Date, otherDate: Date): number {
  firstDate = new Date(firstDate);
  otherDate = new Date(otherDate);

  let years = otherDate.getFullYear() - firstDate.getFullYear();

  if (
    otherDate.getMonth() < firstDate.getMonth() ||
    (otherDate.getMonth() == firstDate.getMonth() && otherDate.getDate() < firstDate.getDate())
  ) {
    years--;
  }

  return years;
}
