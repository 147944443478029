import type { Validation, ValidationArgs } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import type { Ref } from "vue";
import { unref } from "vue";

import { gettext } from "@/core/translations";

const $gettext = gettext.$gettext;

type Propertyof<T> = T[keyof T];
export const getErrors = (field: Propertyof<Validation<ValidationArgs<unknown>, unknown>>) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return field.$error && field.$errors.length > 0 ? [field.$errors[0].$message as string] : [];
};

export const defineRules = <T extends ValidationArgs>(rules: T) => {
  return rules;
};

export function validateMinAge(birthDate: Date, minAge: number) {
  const bday = new Date(birthDate).getTime();
  const today = new Date().getTime();
  const yearsAgo = (today - bday) / (365 * 24 * 60 * 60 * 1000);
  return yearsAgo >= minAge;
}

export const minAge = (minAge: number | Ref<number>) =>
  helpers.withMessage<Date>(
    (_props) =>
      $gettext("Das Alter muss mindestens %{age} Jahre sein.", {
        age: `${unref(minAge)}`,
      }),
    (date) => validateMinAge(date, unref(minAge)),
  );

export function validateMaxAge(birthDate: Date, maxAge: number) {
  const bday = new Date(birthDate).getTime();
  const today = new Date().getTime();
  const yearsAgo = (today - bday) / (365 * 24 * 60 * 60 * 1000);
  return yearsAgo <= maxAge;
}

export const maxAge = (maxAge: number | Ref<number>) =>
  helpers.withMessage<Date>(
    (_props) =>
      $gettext("Das Alter darf maximal %{age} Jahre sein.", {
        age: `${unref(maxAge)}`,
      }),
    (date) => validateMaxAge(date, unref(maxAge)),
  );

const swissPhoneNumberRegex = new RegExp(/^(\+41)( )?\d{2}( )?\d{3}( )?\d{2}( )?\d{2}$/);

export const swissPhoneNumber = () =>
  helpers.withMessage<string>(
    (_props) => $gettext("Das Format ist ungültig. (+41 88 888 88 88)"),
    (phoneNumber) => {
      const machtesRegext = swissPhoneNumberRegex.test(phoneNumber);
      return machtesRegext;
    },
  );

export const notSameSwissPhoneNumber = <T>(fieldName: string, otherValue: T | Ref<T>) =>
  helpers.withMessage<T>(
    (_props) => $gettext("%{field} darf nicht identisch sein.", { field: fieldName }),
    (value) => {
      const numbers = [String(value), String(otherValue)];
      numbers.forEach((nr, index) => {
        const stripped = nr.replaceAll(" ", "");
        const shorted = stripped.substring(stripped.length - 9);
        numbers[index] = shorted;
      });
      return numbers[0] !== numbers[1];
    },
  );
