import type { VirtualProductEvaluation } from "@/types/VirtualProductEvaluation";

export const virtualProductEvaluations: VirtualProductEvaluation[] = [
  {
    id: "KASPAR",
    name: "Kaspar&",
    comment: "",
    additionalProductRelations: [
      {
        id: "DebitMastercard",
        mandatory: true,
      },
      {
        id: "OnlineBanking",
        mandatory: true,
      },
    ],
    productRelationId: "CHECKING_ACTIVE_PLUS",
  },
];

export const getProductRelationIdByVirtualProductId = (virtualProductEvaluationId: VirtualProductEvaluation["id"]) => {
  const res = virtualProductEvaluations.find((vp) => vp.id === virtualProductEvaluationId);
  return res ? res.productRelationId : null;
};
