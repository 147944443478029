declare global {
  interface WindowInterface extends Window {
    logLevel: number;
  }
}

type Environment = "production" | "default" | "test";

type LogType = "info" | "log" | "warn" | "error" | "debug";

type LogLevel = LogType | "all";

enum LogLevelMap {
  all = 0,
  debug,
  info,
  log,
  warn,
  error,
  none,
}

enum EnvLogLevelMap {
  production = LogLevelMap.warn,
  default = LogLevelMap.debug,
  test = LogLevelMap.none,
}

function getEnv(): Environment {
  return (process.env.NODE_ENV as Environment) || "production";
}

function getLevel(env: Environment) {
  if (window && "logLevel" in window) {
    return (window as unknown as WindowInterface).logLevel;
  }

  if (!(env in EnvLogLevelMap)) {
    return EnvLogLevelMap.default;
  }

  return EnvLogLevelMap[env];
}

function levelActive(level: LogLevel, env: Environment) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  return LogLevelMap[level] >= getLevel(env);
}

export class LogService {
  private emitLogMessage(type: LogType, message: string, params: unknown[]) {
    if (console) {
      if (params.length > 0) {
        console[type](message, params);
      } else {
        console[type](message);
      }
    }
  }

  public info(message: string, ...params: unknown[]): void {
    if (levelActive("info", getEnv())) {
      this.emitLogMessage("info", message, params);
    }
  }

  public log(message: string, ...params: unknown[]): void {
    if (levelActive("log", getEnv())) {
      this.emitLogMessage("log", message, params);
    }
  }

  public warn(message: string, ...params: unknown[]): void {
    if (levelActive("warn", getEnv())) {
      this.emitLogMessage("warn", message, params);
    }
  }

  public error(message: string, ...params: unknown[]): void {
    if (levelActive("error", getEnv())) {
      this.emitLogMessage("error", message, params);
    }
  }

  public debug(message: string, ...params: unknown[]): void {
    if (levelActive("debug", getEnv())) {
      this.emitLogMessage("debug", message, params);
    }
  }

  public lowlevel(message: string, ...params: unknown[]): void {
    if (levelActive("all", getEnv())) {
      this.emitLogMessage("log", message, params);
    }
  }
}
