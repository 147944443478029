import type { GetTextOptions } from "vue3-gettext";

import { getRuntimeConfig } from "@/helpers/runtimeData";
import { assertIsDefined } from "@/helpers/types";
import { useLogger } from "@/services";

// Environment variable values in:
// .env (and .env.dist)
// types/env.d.ts
// src/config.ts
// .gitlab/ci-ts/index.ts

type RuntimeConfig = EnvVariables;

const dynamicRuntimeConfig = getRuntimeConfig();

const logger = useLogger();

// --- Base Config ---

const defaultBaseConfig: Pick<RuntimeConfig, "BASE_URL" | "NODE_ENV"> = {
  BASE_URL: "/",
  NODE_ENV: "development",
};

const rawBaseConfig = {
  baseUrl: getString("BASE_URL", defaultBaseConfig),
  nodeEnv: getString("NODE_ENV", defaultBaseConfig),
  mode: getString("MODE", defaultBaseConfig),
  dev: getBool("DEV", defaultBaseConfig),
  prod: getBool("PROD", defaultBaseConfig),
};

assertIsDefined(rawBaseConfig.baseUrl, "BASE_URL");
assertIsDefined(rawBaseConfig.nodeEnv, "NODE_ENV");

const baseConfig = {
  baseUrl: rawBaseConfig.baseUrl,
  nodeEnv: rawBaseConfig.nodeEnv,
  mode: rawBaseConfig.mode,
  dev: rawBaseConfig.dev,
  prod: rawBaseConfig.prod,
};

// --- OIDC Config ---

const defaultOidcConfig: Pick<
  RuntimeConfig,
  "VITE_APP_OIDC_AUTHORITY" | "VITE_APP_OIDC_CLIENT_ID" | "VITE_APP_OIDC_ROLE_NAMESPACES"
> = {
  VITE_APP_OIDC_AUTHORITY: undefined,
  VITE_APP_OIDC_CLIENT_ID: undefined,
  VITE_APP_OIDC_ROLE_NAMESPACES: undefined,
};

const rawOidcConfig = {
  VITE_APP_OIDC_AUTHORITY: getString("VITE_APP_OIDC_AUTHORITY", defaultOidcConfig, true),
  VITE_APP_OIDC_CLIENT_ID: getString("VITE_APP_OIDC_CLIENT_ID", defaultOidcConfig, true),
  VITE_APP_OIDC_ROLE_NAMESPACES: getArray("VITE_APP_OIDC_ROLE_NAMESPACES", defaultOidcConfig, true),
};

assertIsDefined(rawOidcConfig.VITE_APP_OIDC_AUTHORITY, "VITE_APP_OIDC_AUTHORITY");
assertIsDefined(rawOidcConfig.VITE_APP_OIDC_CLIENT_ID, "VITE_APP_OIDC_CLIENT_ID");
assertIsDefined(rawOidcConfig.VITE_APP_OIDC_ROLE_NAMESPACES, "VITE_APP_OIDC_ROLE_NAMESPACES");

const oidcConfig = {
  authority: rawOidcConfig.VITE_APP_OIDC_AUTHORITY,
  clientId: rawOidcConfig.VITE_APP_OIDC_CLIENT_ID,
  roleNamespaces: rawOidcConfig.VITE_APP_OIDC_ROLE_NAMESPACES,
};

// --- I18N Config ---

const defaultI18nConfig: Pick<RuntimeConfig, "VITE_APP_I18N_DEFAULT_LANGUAGE"> = {
  VITE_APP_I18N_DEFAULT_LANGUAGE: "de_CH",
};

const rawI18nConfig = {
  VITE_APP_I18N_DEFAULT_LANGUAGE: getString("VITE_APP_I18N_DEFAULT_LANGUAGE", defaultI18nConfig),
};

assertIsDefined(rawI18nConfig.VITE_APP_I18N_DEFAULT_LANGUAGE, "VITE_APP_I18N_DEFAULT_LANGUAGE");

const i18nConfig: Pick<GetTextOptions, "defaultLanguage" | "availableLanguages"> = {
  defaultLanguage: rawI18nConfig.VITE_APP_I18N_DEFAULT_LANGUAGE,
  availableLanguages: {
    de_CH: "German (Switzerland)",
    en_US: "English (United States)",
  },
};

// --- Services Config ---

const defaultServicesConfig: Pick<
  RuntimeConfig,
  "VITE_APP_SERVICES_CURO_API_URL" | "VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL"
> = {
  VITE_APP_SERVICES_CURO_API_URL: undefined,
  VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL: undefined,
};

const rawServicesConfig = {
  VITE_APP_SERVICES_CURO_API_URL: getString("VITE_APP_SERVICES_CURO_API_URL", defaultServicesConfig, true),
  VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL: getString(
    "VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL",
    defaultServicesConfig,
    true,
  ),
};

assertIsDefined(rawServicesConfig.VITE_APP_SERVICES_CURO_API_URL, "VITE_APP_SERVICES_CURO_API_URL");
assertIsDefined(
  rawServicesConfig.VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL,
  "VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL",
);

const servicesConfig = {
  curo: {
    api: rawServicesConfig.VITE_APP_SERVICES_CURO_API_URL,
  },
  integration: {
    masterdata: {
      api: rawServicesConfig.VITE_APP_SERVICES_INTEGRATION_MASTERDATA_API_URL,
    },
  },
};

// --- Sentry Config ---

const defaultSentryConfig: Pick<RuntimeConfig, "VITE_APP_SENTRY_ENABLED"> = {
  VITE_APP_SENTRY_ENABLED: "false",
};

const rawSentryConfig = {
  VITE_APP_SENTRY_ENABLED: getBool("VITE_APP_SENTRY_ENABLED", defaultSentryConfig, true),
  VITE_APP_SENTRY_DSN: getString("VITE_APP_SENTRY_DSN", defaultSentryConfig),
  VITE_APP_SENTRY_ENVIRONMENT: getString("VITE_APP_SENTRY_ENVIRONMENT", defaultSentryConfig),
  VITE_APP_SENTRY_PROJECT_NAME: getString("VITE_APP_SENTRY_PROJECT_NAME", defaultSentryConfig),
  VITE_APP_SENTRY_VERSION: getString("VITE_APP_SENTRY_VERSION", defaultSentryConfig),
};

assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_ENABLED, "VITE_APP_SENTRY_ENABLED");

if (rawSentryConfig.VITE_APP_SENTRY_ENABLED) {
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_DSN, "VITE_APP_SENTRY_DSN");
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_ENVIRONMENT, "VITE_APP_SENTRY_ENVIRONMENT");
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_PROJECT_NAME, "VITE_APP_SENTRY_PROJECT_NAME");
  assertIsDefined(rawSentryConfig.VITE_APP_SENTRY_VERSION, "VITE_APP_SENTRY_VERSION");
}

const sentryConfig = {
  enabled: rawSentryConfig.VITE_APP_SENTRY_ENABLED,
  dsn: rawSentryConfig.VITE_APP_SENTRY_DSN,
  projectName: rawSentryConfig.VITE_APP_SENTRY_PROJECT_NAME,
  env: rawSentryConfig.VITE_APP_SENTRY_ENVIRONMENT,
  version: rawSentryConfig.VITE_APP_SENTRY_VERSION,
};

// --- GTM Config ---

const defaultGtmConfig: Pick<RuntimeConfig, "VITE_APP_GTM_ENABLED" | "VITE_APP_GTM_DEBUG"> = {
  VITE_APP_GTM_ENABLED: "false",
  VITE_APP_GTM_DEBUG: "false",
};

const rawGtmConfig = {
  VITE_APP_GTM_ENABLED: getBool("VITE_APP_GTM_ENABLED", defaultGtmConfig, true),
  VITE_APP_GTM_DEBUG: getBool("VITE_APP_GTM_DEBUG", defaultGtmConfig),
  VITE_APP_GTM_WHITELIST: getString("VITE_APP_GTM_WHITELIST", defaultGtmConfig),
  VITE_APP_GTM_CONTAINER_ID: getString("VITE_APP_GTM_CONTAINER_ID", defaultGtmConfig),
};

assertIsDefined(rawGtmConfig.VITE_APP_GTM_ENABLED, "VITE_APP_GTM_ENABLED");

if (rawGtmConfig.VITE_APP_GTM_ENABLED) {
  assertIsDefined(rawGtmConfig.VITE_APP_GTM_CONTAINER_ID, "VITE_APP_GTM_CONTAINER_ID");
}

const gtmConfig = {
  enabled: rawGtmConfig.VITE_APP_GTM_ENABLED,
  debug: rawGtmConfig.VITE_APP_GTM_DEBUG,
  whitelist: rawGtmConfig.VITE_APP_GTM_WHITELIST,
  containerId: rawGtmConfig.VITE_APP_GTM_CONTAINER_ID,
};

// --- externalUrls Config ---

const defaultUrlsConfig: Pick<RuntimeConfig, "VITE_APP_LEGAL_NOTES_PDF_URL" | "VITE_APP_IDENTIFICATION_IFRAME_HOST"> = {
  VITE_APP_LEGAL_NOTES_PDF_URL: undefined,
  VITE_APP_IDENTIFICATION_IFRAME_HOST: undefined,
};

const rawUrlsConfig = {
  VITE_APP_LEGAL_NOTES_PDF_URL: dynamicRuntimeConfig.VITE_APP_LEGAL_NOTES_PDF_URL
    ? dynamicRuntimeConfig.VITE_APP_LEGAL_NOTES_PDF_URL
    : import.meta.env.VITE_APP_LEGAL_NOTES_PDF_URL
      ? import.meta.env.VITE_APP_LEGAL_NOTES_PDF_URL
      : defaultUrlsConfig.VITE_APP_LEGAL_NOTES_PDF_URL,
  VITE_APP_IDENTIFICATION_IFRAME_HOST: dynamicRuntimeConfig.VITE_APP_IDENTIFICATION_IFRAME_HOST
    ? dynamicRuntimeConfig.VITE_APP_IDENTIFICATION_IFRAME_HOST
    : import.meta.env.VITE_APP_IDENTIFICATION_IFRAME_HOST
      ? import.meta.env.VITE_APP_IDENTIFICATION_IFRAME_HOST
      : defaultUrlsConfig.VITE_APP_IDENTIFICATION_IFRAME_HOST,
};

assertIsDefined(rawUrlsConfig.VITE_APP_LEGAL_NOTES_PDF_URL, "VITE_APP_LEGAL_NOTES_PDF_URL");
assertIsDefined(rawUrlsConfig.VITE_APP_IDENTIFICATION_IFRAME_HOST, "VITE_APP_IDENTIFICATION_IFRAME_HOST");

const urlsConfig = {
  legalNotesPdf: rawUrlsConfig.VITE_APP_LEGAL_NOTES_PDF_URL,
  identificationIframeHost: rawUrlsConfig.VITE_APP_IDENTIFICATION_IFRAME_HOST,
};

// --- Exports ---

export { baseConfig, gtmConfig, i18nConfig, oidcConfig, sentryConfig, servicesConfig, urlsConfig };
export type { RuntimeConfig };

// --- Utility ---

function getString(
  key: keyof EnvVariables,
  defaultConfig: Partial<Record<keyof EnvVariables, string | undefined>>,
  required = false,
): string | undefined {
  if (dynamicRuntimeConfig[key]) {
    logger.debug("Checking for key in dynamicRuntimeConfig: ", key);
    return dynamicRuntimeConfig[key];
  }
  if (import.meta.env[key]) {
    logger.debug("Checking for key in import.meta.env: ", key);
    return import.meta.env[key];
  }
  if (defaultConfig[key]) {
    logger.debug("Checking for key in defaultConfig: ", key);
    return defaultConfig[key];
  }
  if (required) {
    throw new Error(`Missing required value for config variable "${key}"`);
  }
  return undefined;
}

function getBool(
  key: keyof EnvVariables,
  defaultConfig: Partial<Record<keyof EnvVariables, string | undefined>>,
  required = false,
): boolean | undefined {
  const val = getString(key, defaultConfig, required);
  if (typeof val === "string") {
    return val.toLowerCase() === "true";
  }
  if (typeof val !== "boolean" && required) {
    throw new Error(
      `Value for config variable "${key}" is required and expected to be a boolean or a string, is "${typeof val}"`,
    );
  }
  return undefined;
}

function getArray(
  key: keyof EnvVariables,
  defaultConfig: Partial<Record<keyof EnvVariables, string | undefined>>,
  required = false,
): string[] | undefined {
  const val = getString(key, defaultConfig, required);
  if (typeof val === "string") {
    return val.split(",");
  }
  if (typeof val !== "string" && required) {
    throw new Error(
      `Value for config variable "${key}" is required and expected to be a string (separated with commas), is "${typeof val}"`,
    );
  }
  return undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getInt(
  key: keyof EnvVariables,
  defaultConfig: Partial<Record<keyof EnvVariables, string | undefined>>,
  required = false,
): number | undefined {
  const val = getString(key, defaultConfig, required);
  if (typeof val === "number") {
    return val;
  }
  if (typeof val === "string") {
    return Number(val);
  }
  if (typeof val !== "number" && required) {
    throw new Error(
      `Value for config variable "${key}" is required and expected to be a number or a string, is "${typeof val}"`,
    );
  }
  return undefined;
}
